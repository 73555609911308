import React from 'react';
import './loader.css';

const Loader = () => {
	return (
		<div className='page-loader'>
			<div></div>
			<div></div>
			<div></div>
			{/* <div></div> */}
		</div>
	);
};

export default Loader;
