import React from 'react';
import './portfolio.css';

import { portfolios } from '../../data/data';

const Portfolio = () => {
	return (
		<section id='portfolio'>
			<h5>My Recent Work</h5>
			<h2>Portfolio</h2>
			<div className='container portfolio__container'>
				{portfolios.map((portfolio) => (
					<article className='portfolio__item' key={portfolio.id}>
						<div className='portfolio__item-image'>{portfolio.img}</div>
						<h3>{portfolio.title}</h3>
						<div className='portfolio__item-cta'>
							{portfolio.githublink && (
								<a href={portfolio.githublink} className='btn' target='_blank'>
									Github
								</a>
							)}
							<a
								href={portfolio.demolink}
								className='btn btn-primary'
								target='_blank'>
								Live Demo
							</a>
						</div>
					</article>
				))}
			</div>
		</section>
	);
};

export default Portfolio;
